<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-5">
      <div>
        <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'G7PresupuestoVsRealXCuentaMayor',
  props: {
    datos: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    async datos(val) {
      let presupuesto = [];
      let real = [];
      let categorias = [];
      let formatPresupuesto = [];
      let formatReal = [];
      val.forEach((el) => {
        categorias.push(el.cuentamayor);
        presupuesto.push(el.totalppto);
        real.push(el.totalreal);
        formatPresupuesto.push(el.formatoppto);
        formatReal.push(el.formatoreal);
      });
      this.series[0] = { ...this.series[0], data: presupuesto, format: formatPresupuesto };
      this.series[1] = { ...this.series[1], data: real, format: formatReal };
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            type: 'category',
            categories: categorias,
            ...this.chartOptions.xaxis.labels
          }
        }
      };
    }
  },
  data: () => ({
    series: [
      { name: 'PPTADO', data: [], format: [] },
      { name: 'REAL', data: [], format: [] }
    ],
    chartOptions: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      title: {
        text: 'PRESUPUESTO VS REAL POR CUENTA MAYOR',
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      grid: {
        show: true,
        borderColor: 'rgba(0, 0, 0, .3)',
        strokeDashArray: 2,
        padding: {
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            position: 'top' // top, center, bottom
          },
          endingShape: 'flat'
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val, obj) {
          return obj.w.config.series[obj.seriesIndex].format[obj.dataPointIndex];
        },
        offsetY: -15,
        style: {
          fontSize: '9px',
          colors: ['#304758']
        }
      },
      xaxis: {
        categories: [],
        labels: {
          trim: true,
          style: {
            fontSize: '10px',
            fontWeight: 'bolder',
            cssClass: 'text-muted fill-color'
          }
        }
      },
      yaxis: {},
      legend: {
        show: true,
        position: 'top',
        fontSize: '11px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      fill: {
        type: 'solid',
        opacity: 1
      },
      colors: ['#9fa6a7', '#007e49']
    }
  }),
  components: {
    VueApexCharts
  }
};
</script>
